import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './navigatiion/navigation';
import { GlobalStateProvider } from './state/globalState';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalStateProvider>
 <React.StrictMode>

<BrowserRouter>

<App />
</BrowserRouter>

</React.StrictMode>

  </GlobalStateProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
