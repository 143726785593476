
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

import icon from '../assets/icon.png'
import { useContext } from 'react';
import { GlobalStateContext } from '../state/globalState';
function Navigation() {
  const { user, setCurrentUser } = useContext(GlobalStateContext);
  useEffect(()=>{
user?setRouteList(PrivateRouteList):setRouteList(GuestRouteList)
  },[user])

  const navigation=useNavigate()
  const [active,setActive]=useState()
  const [RouteList,setRouteList]=useState([])

const GuestRouteList=[  {routename:'Home',path:'/'},
  {routename:'Signup',path:"/signup"},
  {routename:'login',path:'/login'},
 ]
 const PrivateRouteList=[
  {routename:'Home',path:'/'},
   {routename:'Chef',path:"/dashboard"},
   {routename:'Sign Out',path:"/logout"},
   {routename:'account',path:"/myaccount"}


 ]
 
  return (
      
    <div className='container-fluid d-flex justify-content-center '>
   
       <div className='MainLogo' onClick={()=>{
        navigation('/')
       }}>
       <img src={icon} width={50} height={50}/>
        <h1 >
            MyAiChef
        </h1>
       </div>
        {/* <nav className='nav navbar d-flex justify-content-around w-25 toggleable ' >
      <Link  className='text-white text-decoration-none'  to={'/'}> Home</Link>

     <Link className='text-white text-decoration-none'  to={'/signup'}>Sign up </Link>
      </nav> */}

<nav className="navbar navbar-expand-lg navbar-light bg-dark mx-5 ">
  <button className="navbar-toggler bg-white text-dark" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon bg-white"></span>
  </button>
  <div className="collapse navbar-collapse " id="navbarTogglerDemo01">
    <ul className="navbar-nav mr-auto mt-2  mt-lg-0">
     

{RouteList.map((route)=>{
  return <li key={RouteList.indexOf(route)} className="nav-item ">
    <p key={RouteList.indexOf(route)}
                      onClick={(event) => {
                        
                      if(route.routename=='Sign Out'){
                        if(window.confirm('Sign Out ?')==true){
                          setActive(route.routename)
                          navigation(route.path)
                        } 
                        

                      }else{
                        navigation(route.path)

                        setActive(route.routename)

                      }
                        }}

        className={`nav-link  ${active === route.routename ? 'active' : ''}`} 

        
       >{route.routename}</p> 

  </li>
})}

    </ul>
  </div>
</nav>

    </div>
     
      
 
  );
}

export default Navigation;
