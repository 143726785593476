import process from "process";

export async function promptAi(model, payload) {
    const response = await fetch(
      `https://chefkitchen.thebreakerone99.workers.dev`,
      {
        headers: {     "Accept": "*/*",
    
          "Content-Type": "application/json"},
        method: "POST",
        body: JSON.stringify(payload),    
        mode: 'cors',

      }
    );
    const result = await response.json();
    return result;
  }
  
  
  ;