import logo from './logo.svg';
import './App.css';
// App.js
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Auth from './auth/auth';
import { Route, Routes } from 'react-router';
import Home from './landing/home';
import { Link } from 'react-router-dom';
import Navigation from './navigatiion/navigation';
import SignUp from './auth/signup';
import PasswordReset from './auth/passwordReset';
import Dashboard from './bot/dashboard';
import { useEffect, useState } from 'react';
import { auth } from './firebase';
import { useContext } from 'react';
import { GlobalStateContext } from './state/globalState';
import Logout from './auth/logout';
import Myaccount from './auth/Myaccount';

function App() {
  const { user, setCurrentUser } = useContext(GlobalStateContext);

  return <>
<Navigation/>
<ToastContainer/>
      <Routes  >
     
        <Route element={<Home/>} path='/'></Route>
        <Route element={<Dashboard/>}  path='/dashboard' ></Route>
        <Route element={<Logout/>} path='/logout' ></Route>
        <Route element={<Myaccount/>} path='/myaccount' ></Route>
        <Route element={<SignUp/>} path='/signup'></Route>
        <Route element={<Auth/>} path='/login'></Route>  
        <Route element={<PasswordReset/>} path='/PasswordReset'></Route> 
   
        <Route element={<p style={{position:'absolute',top:'50%',left:'45%'}} > 404 Not Found</p>} path='/*'></Route> 

      </Routes>
      </>
 
  
}

export default App;
