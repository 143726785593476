// File: Auth.js
import React, { useEffect, useState } from 'react';

import { auth,reauthenticateWithPopup,GoogleAuthProvider, signInWithPopup, deleteUser } from '../firebase';

import SignInWithGoogleButton from './signInwithGoogle';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalStateContext } from '../state/globalState';
import { isAuthenticated } from '../state/globalState';
const Myaccount = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [width,setWidth]=useState(window.innerWidth)
  const [height,setHeight]=useState(window.innerWidth)
  const navigation=useNavigate()
  const { user, setCurrentUser } = useContext(GlobalStateContext);
  const [loading,setLoading]=useState(false)

  useEffect(()=>{
    let user= isAuthenticated()
    
   if(!user){
   
     navigation('/')
   }else{
    setCurrentUser(user)
   }
   },[])
window.onresize=(()=>{
  setWidth(window.innerWidth)
  setHeight(window.innerHeight)


})
  

  const handlDelete = async() => {
if(email!==user.email){
  toast.warning('Email incorrect')
  return
}

setLoading(true)


// const credential = EmailAuthProvider.credential(email, password); // Get credentials


  // await reauthenticateWithCredential(user, credential);
  const provider = new GoogleAuthProvider(); // Google provider

  // console.log(user)
  
    try{
      // deleteUser(user)
      auth.currentUser?.delete()
    }catch(err){
      console.log(err.code)
      // const result = await signInWithPopup(auth, provider);
      // await result.user.delete()


    }
    setLoading(true)
    toast.success('Deleted with success')


        localStorage.clear()
        setCurrentUser(null)
        setLoading(false)
        navigation('/')
  };
 

  return (<>
    <div  className="spinner-border position-absolute " 
      style={{left:'50%',top:'50%',display:loading?'block':'none',zIndex:loading?100:-5}} role="status">

      </div>

      <div id='auth' className='container d-flex flex-column 
     justify-content-between align-content-stretch mt-5  w-100 h-100 ' style={{opacity:loading?0.5:1}}>
      <h2>{user?.displayName}</h2>
      <p>
        Type in your email to confirm account deletion,note that all your data will be deleted
      </p>

      <input
                className='form-control mt-4 input  bg-white text-dark align-self-center '
required
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      


      <button 
         className='btn btn-warning  align-self-center m-2'
      onClick={handlDelete}>Delete my Account</button>


   
    </div>
  </>
   
  );
};

export default Myaccount;
