// File: Auth.js
import React, { useEffect, useState } from 'react';

import { auth } from '../firebase';
import {deleteUser, EmailAuthProvider, GoogleAuthProvider, 
  reauthenticateWithPopup, createUserWithEmailAndPassword,
   signInWithEmailAndPassword,signOut,reauthenticateWithCredential
  ,sendSignInLinkToEmail, sendEmailVerification,verifyPasswordResetCode,
    sendPasswordResetEmail,checkActionCode } from 'firebase/auth';
import SignInWithGoogleButton from './signInwithGoogle';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalStateContext } from '../state/globalState';
import { isAuthenticated } from '../state/globalState';
const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [width,setWidth]=useState(window.innerWidth)
  const [height,setHeight]=useState(window.innerWidth)
  const navigation=useNavigate()


  const { user, setCurrentUser } = useContext(GlobalStateContext);
  const [loading,setLoading]=useState(false)
  useEffect(()=>{
    let user= isAuthenticated()
   if(user){
     setCurrentUser(user)
     navigation('/dashboard')
   }
   },[])
 
window.onresize=(()=>{
  setWidth(window.innerWidth)
  setHeight(window.innerHeight)


})
  

  const handleSignIn = async() => {
if(email=="" || password==""){
  toast.warning('Please fill out all fields!')
  return
}
setLoading(true)
   await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem('user',JSON.stringify(userCredential.user))
        setCurrentUser(userCredential.user)
  
        navigation('/dashboard')
      })
      .catch((error) => {
        toast.error('Invalid email/password,please try again!')

        console.error('Error signing in:', error);
      });
      setLoading(false)
  };
 

  return (<>
    <div  className="spinner-border position-absolute " 
      style={{left:'50%',top:'50%',display:loading?'block':'none',zIndex:loading?100:-5}} role="status">

      </div>

      <div id='auth' className='container d-flex flex-column 
     justify-content-between align-content-stretch mt-5  w-100 h-100 ' style={{opacity:loading?0.5:1}}>

      <input
                className='form-control mt-4 input  bg-white text-dark align-self-center '
required
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
      // style={{width:width*0.4}}
            className='form-control mt-4 input bg-white text-dark  align-self-center '
required
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />


      <button 
         className='btn btn-warning  align-self-center m-2'
      onClick={handleSignIn}>Sign In</button>


      
      <Link to={'/PasswordReset'} className="btn btn-warning align-self-center m-4 text-decoration-none bg-warning text-dark">
        Forget your password? Click here</Link>
<p className='text-center align-self-center'  >Or</p>
<SignInWithGoogleButton />
    </div>
  </>
   
  );
};

export default Auth;
