// SignInButton.js
import React from 'react';
import { auth,provider,signInWithRedirect,signInWithPopup } from '../firebase';
import gLogo from '../assets/g-logo.png'
import { useContext } from 'react';
import { GlobalStateContext } from '../state/globalState';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
const SignInWithGoogleButton = ({signup=false}) => {
  const navigation=useNavigate()

  const { user, setCurrentUser }=useContext(GlobalStateContext)
  const signInWithGoogle = async () => {
    // console.log('begin sign in')
    try {
      const result = await signInWithPopup(auth, provider);
      // console.log(result.user)
      const user = result.user;
      // user.delete()
     
      localStorage.setItem('user',JSON.stringify(user))
      setCurrentUser(user)
      navigation('/dashboard')

      // console.log('User signed in: ', user);
    } catch (error) {
      console.error('Error during sign-in: ', error);
      toast.error('an error occured,please try again later')
    }
  };



  const logoStyle = {
    marginRight: '10px',
    width: '20px',
    height: '20px',
  };
const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#4285F4',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '16px',
  marginTop:  !signup?'0px':'100px'
};


  return (
    <button onClick={signInWithGoogle} style={buttonStyle}>
    <img src={gLogo} alt="Google logo" style={logoStyle} />

     Sign {signup?'up':'in'}  with Google
    </button>
  );




  
};

export default SignInWithGoogleButton;
