import React, { useState, useEffect, useRef, useCallback } from 'react';
import { promptAi } from './prompt';
import { toast } from 'react-toastify';
import icon from '../assets/icon.png'
import cooker from '../assets/cooker.png'
import { db ,setDoc,doc,addDoc,collection,createOrUpdateDoc, getRecipes, auth} from '../firebase';
import { isAuthenticated } from '../state/globalState';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import { GlobalStateContext } from '../state/globalState';


const Dashboard = () => {
  const promptMessage= {
    role: "system",
    content: "You are a friendly kitchen chef assistant,you help with recipes and how to cook them",
  }
  const { user, setCurrentUser }=useContext(GlobalStateContext)

  const [messages, setMessages] = useState(
    [
     promptMessage
    ]
  );
  const [input, setInput] = useState('');
const [width,setWidth]=useState(window.innerWidth)
const [cooking,setCooking]=useState(false)
const navigation=useNavigate()
const {cache,setCache}=useContext(GlobalStateContext)
const [FontSize,SetFontSize]=useState()
useEffect(()=>{
  let user= isAuthenticated()
  
 if(!user){
 
   navigation('/')
 }else{
  setCurrentUser(user)
  
 }
 },[])

useEffect(()=>{
if(messages.length>=2 && messages[messages.length-1].role=="system"){
  // console.log(messages)
  // console.log('qeutrin db')
createOrUpdateDoc('converstations','userid',user.uid,{messages:messages})
}
},[messages]) 

const [chatContainerWidthMargin,setChatContainerWidthMargin]=useState(width*0.21)

const [chatContainerWidth,setChatContainerWidth]=useState(width*0.6)
useEffect(()=>{
  if(width<=700){
    // console.log('in mobile')
    SetFontSize(12)
    setChatContainerWidth(width*0.9)

    setChatContainerWidthMargin(width*0.02)

  }else{
    setChatContainerWidth(width*0.6)
    SetFontSize(width*0.01)
    setChatContainerWidthMargin(width*0.05)
  }
},[width])
useEffect(()=>{
 const populateData=async()=>{
  setCooking(true);

  if(cache.db && cache?.messages?.length>0) {
    setMessages(cache.messages) 
    setCooking(false);

    return}
  let recipes=localStorage.getItem('recipes')
// console.log('popluta')
  if(recipes==null){
    // console.log('qeutrin db for read')

    let messages=await getRecipes('converstations','userid',user?.uid)
    if(messages){    setMessages(messages[0].messages)
      setCache({db:true,messages:messages[0].messages})
      localStorage.setItem('recipes',JSON.stringify(messages[0]?.messages))

    }else if(messages==false){
      toast.warning('error occured while fetching your recipes,please refresh this page')
    }
    setCache({db:true})
   

  }else if(recipes){
    // console.log('from cache')
    setMessages(JSON.parse(recipes))}
    setCooking(false);

 }
 if(user){
  populateData()
 }

},[user])
const converstation=useRef()
window.onresize=()=>{
    setWidth(window.innerWidth)
}
const chatContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '90vh',
  justifyContent: 'flex-end',
  alignSelf:'center',
  minWidth:chatContainerWidth,
  justifySelf:'center',
  alignSelf:'center',
  position:'absolute',

  left:chatContainerWidthMargin
};
const messageStyle = {
    // padding: '10px',
    wordWrap: 'break-word',
    width:'50%',
    padding: '10px',
    fontSize:FontSize,
    // border:'0.5px solid white',

    marginLeft: '50%',
  //   backgroundColor: '#e3e3e3',
    borderRadius: '4px',
  };
  const buttonStyle = {
    padding: '20px',
    backgroundColor: '#4285F4',
    color: 'white',
    border: 'none',
    maxWidth:width*0.3,
    borderRadius: '4px',
    cursor: 'pointer',
    alignSelf:'center'
    
  };
  const  messageBot= {
    padding: '10px',
    margin: '5px 0',
    width:'95%',
    fontSize:FontSize,

    // border:'0.5px solid white',
  //   backgroundColor: '#e3e3e3',
    borderRadius: '4px',
    wordWrap: 'break-word'
  };
  const scrollToBottom=()=>{    converstation.current.scrollBy({behavior:"smooth",top:converstation.current.scrollHeight } )}

//   const sendMessage=async()=>{

//     if(input=="" || cooking) return
//     let Nmessages=[...messages,{role:'user',content:input}]
   
   
//      scrollToBottom()
// setCooking(true)
//     setMessages(Nmessages)


// const payload={messages:Nmessages}
// setInput('')
// // console.log(payload)

//    await promptAi("@cf/meta/llama-3-8b-instruct", payload).then((response) => {

//     let Nmessages=[...messages,{role:'system',content:response?.result?.response}]

//       console.log(Nmessages);
//       setMessages(Nmessages)


//     }).catch(()=>{
//       toast.warning('aie aie kitchen is under a lot of pressure please try again later')
//     })

//     // let convo=document.querySelector('#converstation')
//     // console.log(convo.scrollHeight)
// setCooking(false)
//   }
  

  const sendMessage = async () => {
    if (input === "" || cooking) return;
    scrollToBottom();

  
    // Add user message to the state
    setMessages(prevMessages => {
      const newMessages = [...prevMessages, { role: 'user', content: input }];
      return newMessages;
    });
  
    // Scroll to the bottom after adding user message
    setCooking(true);
    setInput('');
  
    const payload = { messages: [promptMessage,{ role: 'user', content: input }] };
  
    try {
      const response = await promptAi("@cf/meta/llama-3-8b-instruct", payload);
      // console.log(response?.result?.response)
  
      // Add system response to the state
      setMessages(prevMessages => {
        const newMessages = [...prevMessages, { role: 'system', content: response?.result?.response }];
        // console.log(newMessages)
        localStorage.setItem('recipes',JSON.stringify(newMessages))
        return newMessages;
      });
    
     
      // try{
      //   await addDoc(collection(db, 'converstations'), data);
      //   console.log('saved with sucess')
      // }catch(err){
      //   console.log(err)

      // }
    
  


      // Save or update the user's data in Firestore
    }  
       catch (error) {
      toast.warning('aie aie kitchen is under a lot of pressure please try again later');
    } finally {
      setCooking(false);
    }
  };
  

  useEffect(() => {


  }, []);


  return (
    <div    style={chatContainerStyle} >
      <div style={{position:"absolute",top:"50%",left:"40%",display:cooking?"block":"none"}}>
<img className='animate-cooking' src={cooker} width={60} height={60}/>
      </div>
      <div id='converstation' ref={converstation}  style={messageContainerStyle} >
        {messages?.map((message) =>{
          let index=messages.indexOf(message)
          return  index!==0?
          <div key={index} style={message.role=='user'?messageStyle:messageBot}>
           {message.role=='system'?<img key={index} src={icon} width={20} height={20}/>:null}

            {message.content}
          </div>:<div key={index} style={messageBot}>
          <img key={index} src={icon} width={20} height={20}/>
            {"hello Master what are you cooking today"}
          </div>
        
        })}
      </div>
      <textarea
    onKeyDown={(event)=>{
      // console.log(event.key)
    
      if(event.key=='Enter'){
sendMessage()


}

    }}
      placeholder='How to make italien lasagna'
      className='prompt-box  form-control bg-dark text-white'
       type="text"
       value={input}
       onChange={(e) => setInput(e.target.value)}
       style={inputStyle}
      >  </textarea>
       
  
      <button onClick={()=>{

sendMessage()
      }} style={buttonStyle}>Send</button>
    </div>
  );
};



const messageContainerStyle = {
  flex: 1,
  padding: '10px',
  flexWrap:'wrap',
  overflowY: 'auto',
  overflowX: 'hidden',
  // backgroundColor: '#f5f5f5',

};



const inputStyle = {
  padding: '10px',
  width: '100%',
  minHeight:'90px',
  borderRadius:'5px',
  color:'white'
 
};


export default Dashboard;
