import { createContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
// Create a context
export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [user, setCurrentUser] = useState();
    const [cache, setCache] = useState({});
    useEffect(()=>{
setCurrentUser(auth.currentUser)
    },[])
  
    return (
      <GlobalStateContext.Provider value={{ user, setCurrentUser,cache,setCache }}>
        {children}
      </GlobalStateContext.Provider>
    );
  };
  
  export const isAuthenticated=()=>{
    let user=localStorage.getItem('user')
        user=JSON.parse(user)
       
        if(user){
          return user
        }
        return false

  }