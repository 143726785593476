// File: Auth.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';

import { sendPasswordResetEmail } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [width,setWidth]=useState(window.innerWidth)

window.onresize=(()=>{
  setWidth(window.innerWidth)

})

  const handleSignIn = () => {
if(email=="" ){
  toast.warning('Please provide your email !')
  return
}
handlPasswordReset()

   
  };
  const handlPasswordReset=()=>{
    sendPasswordResetEmail(auth,email).then(()=>{
        toast.success('email sent successfully')
        console.log('password reset email sent')
    }).catch(()=>{
        toast.error('sorry an error occured !!')

    })
  }

  return (
    <div id='auth' className='container d-flex flex-column 
     justify-content-between align-content-stretch mt-5  w-100 h-100 '>
<p>Fill out your email to recieve a password reset email</p>
      <input
                className='form-control mt-4 input  bg-white text-dark align-self-center '
required
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      

      <button 
         className='btn btn-warning  align-self-center m-2'
      onClick={handleSignIn}>Send reset Email</button>

    </div>
  );
};

export default PasswordReset;
