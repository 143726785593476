// File: Auth.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';

import { sendPasswordResetEmail, signOut } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useNavigation } from 'react-router';
import { useContext } from 'react';
import { GlobalStateContext } from '../state/globalState';
const Logout = () => {
    const { user, setCurrentUser } = useContext(GlobalStateContext);
    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [width,setWidth]=useState(window.innerWidth)
  const navigation=useNavigate()
  useEffect(()=>{
const loggingOut=async()=>{
    
  localStorage.setItem('user',null)
    await auth.signOut()
    setCurrentUser(null)
    navigation('/')

}
loggingOut()
},[])
  
  return<>{}</> 
};

export default Logout;
