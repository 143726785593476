import React, { useState } from 'react';
import { useNavigate, useNavigation } from 'react-router';
import icon from '../assets/icon.png'
import { auth } from '../firebase';

const Home = () => {
  const [width,setWidth]=useState(window.innerWidth)
  const Xaccount="https://x.com/mohamedelaaouam"
  const upworkAccount='https://www.upwork.com/freelancers/~017b679f73bc06876a'
 const navigation=useNavigate()
 const [details,setDetails]=useState(false)
 window.onresize=()=>{
  setWidth(window.innerWidth)
}
// console.log(auth.currentUser)
  return (
    <div className='container-fluid mt-5  ' style={{minHeight:"100vh"}}>
<div className='row mt-5 pt-5 mx-5'>

<div className='col ' style={{width:width}}>
<p  style={{fontSize:width*0.04}}>
      With our Ai chef you can Generate delecious recpies
    </p>
    <button className='btn btn-warning' style={{fontSize:width*0.02,fontWeight:'bold'}} onClick={()=>{
      navigation('/signup')
    }}> Sign up for free</button>
  
</div>
<div className='col'>
 
<img src={icon} style={{width:width*0.2,marginLeft:width*0.08}}  />
</div>
</div>

<div className='row mt-5  mx-5'>


<div className='col'>
  <p>FAQ</p>
<details >
  <summary>
   is MyAiChef free
  </summary>

  <p  >yes you are free to use this project and generate as many recipes as you want</p>
</details>
<details >
  <summary>
is my data safe  
</summary>

  <p >this project uses google infastructure mainly firebase to save and manage data,so your data is safe,and deleted on your request</p>
</details>
<details >
  <summary>
   Can I reach out to the developer
  </summary>

  <p >yes you can reach out to me my <a
  style={{textDecorationLine:'none',color:'green',fontWeight:"bold"}}
  href={Xaccount}>X account</a>  or <a 
  style={{textDecorationLine:'none',color:'green',fontWeight:"bold"}}
  href={upworkAccount}>upwork</a> </p>
</details>
</div>
</div>
    </div>
  );
};

export default Home;
