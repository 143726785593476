// File: Auth.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword
    ,sendSignInLinkToEmail,sendEmailVerification,verifyPasswordResetCode,sendPasswordResetEmail,checkActionCode } from 'firebase/auth';
import SignInWithGoogleButton from './signInwithGoogle';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { GlobalStateContext, isAuthenticated } from '../state/globalState';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { user, setCurrentUser } = useContext(GlobalStateContext);
  const navigation=useNavigate()
  const [loading,setLoading]=useState(false)
  // const [width,setWidth]=useState(window.innerWidth)
  useEffect(()=>{
   let user= isAuthenticated()
  if(user){
    setCurrentUser(user)
    navigation('/dashboard')
  }
  },[])


  const handleSignUp =async () => {
if(email=="" || password==""){
  return toast.warning('please fill all fields')
}
if(confirmPassword!=password ){
  return toast.warning('passwords dont match !!')

}
setLoading(true)

    await  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // console.log('User signed up:', userCredential.user);
      localStorage.setItem('user',JSON.stringify(userCredential.user))
      setCurrentUser(userCredential.user)

      navigation('/dashboard')
      
    })
    .catch((error) => {
      toast.error("sorry an error occured,please try again later")

      // console.error('Error signing up:', error);
    });
    setLoading(false)


  };

window.onresize=(()=>{


})
  return (
    <>
     <div  className="spinner-border position-absolute " 
      style={{left:'50%',top:'50%',display:loading?'block':'none',zIndex:loading?100:-100}} role="status">

      </div>

      <div id='auth' className='container d-flex flex-column 
     justify-content-between  w-100 h-100 mt-5 ' style={{opacity:loading?0.5:1}}>
    

      
<input
                className='form-control mt-4 input  bg-white text-dark align-self-center '
required
        type="email"
        
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
      // style={{width:width*0.4}}
            className='form-control mt-4 input bg-white text-dark  align-self-center '
required
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
   <input
      // style={{width:width*0.4}}
            className='form-control mt-4 input bg-white text-dark  align-self-center '
required
        type="password"
        placeholder="confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <button 
         className='btn btn-warning  align-self-center m-2'
      onClick={handleSignUp} disabled={loading}>Sign Up</button>
      
      <SignInWithGoogleButton disabled={loading} signup={true} />
   
    <p  className=' text-center align-self-center m-5'> <Link to={'/login'}
     className='text-decoration-none text-white' >or Login Instead</Link></p>
    </div> 
      
      
      </>
  
  
  );
};

export default SignUp;
