// File: firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore,setDoc,doc, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { signInWithPopup,signInWithRedirect,getAuth, EmailAuthProvider, GoogleAuthProvider, 
  reauthenticateWithPopup, createUserWithEmailAndPassword,
   signInWithEmailAndPassword,signOut,reauthenticateWithCredential
  ,sendSignInLinkToEmail, sendEmailVerification,verifyPasswordResetCode,deleteUser,
    sendPasswordResetEmail,checkActionCode} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { Firestore } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
// Firebase configuration from the Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyAwFUDfhOgw2soLRALAkew70nkSoDokjpc",
  authDomain: "myaichef-7da50.firebaseapp.com",
  projectId: "myaichef-7da50",
  storageBucket: "myaichef-7da50.appspot.com",
  messagingSenderId: "746821775191",
  appId: "1:746821775191:web:07b499c968d81ee04b5812",
  measurementId: "G-THXJRYGNK8"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const provider=new GoogleAuthProvider()

const createOrUpdateDoc = async (collectionName, customFieldName, customFieldValue, newData) => {
  const collectionRef = collection(db, collectionName);

  // Step 1: Query the collection for documents where customFieldName matches customFieldValue
  const q = query(collectionRef, where(customFieldName, "==", customFieldValue));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // Step 2: If the document exists, update it
    querySnapshot.forEach(async (docSnapshot) => {
      const docRef = doc(db, collectionName, docSnapshot.id); // Use the document ID to reference it
      await setDoc(docRef, newData, { merge: true }); // Update the document with new data (merge = update fields, not overwrite)
      // console.log("Document updated with ID: ", docSnapshot.id);
    });
  } else {
    // Step 3: If no document exists, create a new one
    const newDocRef = doc(collectionRef); // Auto-generate a new document ID
    await setDoc(newDocRef, {
      ...newData,
      [customFieldName]: customFieldValue, // Ensure the custom field is part of the new document
    });
    // console.log("New document created with ID: ", newDocRef.id);
  }
};

const getRecipes = async (collectionName, customFieldName, customFieldValue) => {
  const collectionRef = collection(db, collectionName);
  let messages=null

  // Step 1: Query the collection where customFieldName equals customFieldValue
  const q = query(collectionRef, where(customFieldName, "==", customFieldValue));


    // Step 2: Get the documents that match the query
   await getDocs(q).then((snap)=>{

      if (!snap.empty) {
        messages=[]
        // Step 3: Loop through and retrieve the documents
        snap.forEach((docSnapshot) => {
          messages.push(docSnapshot.data())
          // console.log(`Document ID: ${docSnapshot.id}`, );
        });
  
      } else {
        // console.log("No documents found.");
      }
    }).catch(()=>{
      messages=false
      console.log('error')
    });


  return messages


};

const auth = getAuth(app);

const db = getFirestore(app);
const storage = getStorage(app);

export { auth,deleteUser, 
  db,setDoc,doc,collection,addDoc, 
  storage,signInWithRedirect,getRecipes,provider,createOrUpdateDoc,updateDoc,signInWithPopup,signOut,GoogleAuthProvider,reauthenticateWithPopup};
